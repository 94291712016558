.body {
  text-align: center;
}

.title {
  font-weight: normal;
}
.search-surnames{
  height: 40px;
  width: 200px;
  font-size: 20px;
}

.textarea {
  margin-top: 20px;
  font-size: 20px;
  padding: 20px;
  width: 231px;
  height: 300px;
  
}

