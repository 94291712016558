@media screen and (min-width: 701px) {

.steps-all{
  padding: 5%;
}

.steps{
  display: flex;
  justify-content: space-around;
  align-items: center;
}
.steps2{
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 14%;
}

.img-steps{
  width: 200px;
  height: 150px;
  margin: 1%;
}

.img-steps:hover{
  width: 500px;
  height: 500px;
}

.link-btn{
  margin-top: 20%;
  padding: 8px;
  width: 200px;
  height: 70px;
  border: 1px solid teal;
  background-color: teal;
  border-radius: 50%;
  color: white;
  
  text-decoration: none !important;
}

.link-txt{
  margin-top: 8%;
  font-size: 16px;
}

}
@media screen and (max-width: 700px) {


  .setps-all{
    margin-top: 10%;
  }
  

  .steps{
    margin-left: auto;
    margin-right: auto;
    width: 200px;
    display: block;
    justify-content: center;
    align-items: center;
  }
  .steps2{
    margin-left: auto;
    margin-right: auto;
    width: 200px;
    display: block;
    justify-content: center;
    align-items: center;
  }
  
  .img-steps{
    width: 200px;
    height: 200px;
  }

  .steps-title{
    width: 200px;
    margin-top: 70%;
    color: teal;
  }
}