@media screen and (min-width: 701px) {
    .test {
        font-size: 16px;
        color: black;
        display: flex;
        justify-content: space-around;
        align-items: center;
      }
    
      .test2 {
        width: 500px;
        height: 500px;
        display: block;
        justify-content: center;
        align-items: center;
        border: 1px solid teal;
        padding: 1%;
      }
      .test1 {
        width: 500px;
        height: 500px;
        display: block;
        justify-content: center;
        align-items: center;
        border: 1px solid teal;
        padding: 0.5%;
      }
    
      .test-title {
        text-align: center;
        font-size: 20px;
        color: teal;
      }
    
      .test-foot {
        text-align: right;
      }
}
@media screen and (max-width: 700px) {
    .test {
        font-size: 16px;
        color: black;
        display: block;
        justify-content: center;
        align-items: center;
      }
    
      .test2 {
          margin-left: auto;
          margin-right: auto;
          margin-top: 2%;
        width: 280px;
        height: 750px;
        display: block;
        justify-content: center;
        align-items: center;
        border: 1px solid teal;
        padding: 3%;
      }
      .test1 {
        margin-left: auto;
          margin-right: auto;
          margin-top: 2%;
        width: 280px;
        height: 900px;
        display: block;
        justify-content: center;
        align-items: center;
        border: 1px solid teal;
        padding: 3%;
      }
    
      .test-title {
        text-align: center;
        font-size: 20px;
        color: teal;
      }
    
      .test-foot {
        text-align: right;
      }
}
