@media screen and (min-width: 701px) {
  .txt-methodology {
    font-size: 20px;
    color: black;
    margin-left: 100px;
    max-width: 600px;
  }
  .img-methodology {
    width: 300px;
    border-radius: 90%;
  }

  .about-methodology {
    display: flex;
    justify-content: space-around;
    align-items: center;
  }

  .examples-btn {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 30px;
    font-size: 26px;
    border-radius: 25px;
    color: white;
    border-color: teal;
    background-color: teal;
    width: 320px;
    height: 50px;
  }
}
@media screen and (max-width: 700px) {
  .txt-methodology {
    font-size: 20px;
    color: black;
    margin-left: auto;
    max-width: auto;
    padding: 3%;
  }
  .img-methodology {
    width: 300px;
    border-radius: 90%;
  }

  .about-methodology {
    display: block;
    justify-content: center;
    align-items: center;
  }

  .examples-btn {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 30px;
    font-size: 26px;
    border-radius: 25px;
    color: white;
    border-color: teal;
    background-color: teal;
    width: 320px;
    height: 50px;
  }
}
