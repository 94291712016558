@media screen and (min-width: 700px) {
  .contact {
    padding: 1%;
    width: 800px;
    margin-left: auto;
    margin-right: auto;
    border: 1px solid teal;
    display: block;
    justify-content: center;
    align-items: center;
  }

  .label {
    color: teal;
    font-size: 16px;
  }

  .btn {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 30px;
    font-size: 26px;
    border-radius: 25px;
    color: white;
    border-color: teal;
    background-color: teal;
    width: 160px;
    height: 50px;
  }
}
@media screen and (max-width: 700px) {

    .contact {
        padding: 1%;
        width: 250px;
        margin-left: auto;
        margin-right: auto;
        border: 1px solid teal;
        display: block;
        justify-content: center;
        align-items: center;
        margin-top: 10%;
      }

      .label {
        color: teal;
        font-size: 16px;
      }
    
      .btn {
        display: block;
        margin-left: auto;
        margin-right: auto;
        margin-top: 30px;
        font-size: 26px;
        border-radius: 25px;
        color: white;
        border-color: teal;
        background-color: teal;
        width: 160px;
        height: 50px;
      }


    }

