.App {
  text-align: center;
}

.logo{
  width: 200px;
}



.body {
  font-size: 10px;
  background-color: #eaeaea;
  color: white;
  font-family: 'Palatino';
  
}

.title {
  text-align: center;
  color: teal;
  padding: 2%;
  font-size: 30px;
  text-decoration-color: teal;
  
}


.after-login{
  font-size: 20px;
  padding: 0.7%;
  color: #eaeaea;
  background-color: goldenrod;
  text-decoration: none !important; 
  border: 1px solid goldenrod;
  border-radius: 30px;
  width: 300px;
  margin: 160px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}
.after-login:hover{
  color: #eaeaea;
}



