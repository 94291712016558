@media screen and (min-width: 701px) {

    
    .examples{
        margin-left: 100px;
        
    }
}    
    @media screen and (max-width: 700px) {
        .examples{
            margin-left: 0px;
            max-width: 400px;
            max-height: 500px;
            
        }
    }
  
  