.start{
    display: block;
    background-color: #eaeaea;
  
    
  }
  
  .start-title{
    text-align: center;
    font-size: 26px;
    color: teal;
    
  }
  
  .img{
    border-radius: 99px;
    width:400px;
    display: block;
    margin-left: auto;
    margin-right: auto;
  }

 